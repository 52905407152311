.Tiles {

}

/* @media only screen and (max-width: 1350px) {
  .card {
    min-width: 266px;
  }
} */

/* .Tiles img {
  min-width: 266px;
} */

.hr_inline {
    display: inline;
    text-align: left;
  }

hr {
      
    border: 1px solid lightgrey;
  }

