/* scss style to re-add upon scss conversion */
/* $enable-shadows:true; */

.btn-primary {
    color: palegreen;
    background-color: darkcyan;
    border-color: darkcyan;
}
 
.mycard {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	width: 40%;
	margin: 5%;
}

/* On mouse-over, add a deeper shadow */
.mycard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.mycontainer {
	padding: 2px 16px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* .flip-card-outer {
  display: inline-block;
} */

.flip-card {
  /* display: inline-block; */
  background-color: transparent;
  width: 150px; /* 300px; */
  height: 150px; /* 300px; */
  object-fit: cover;
	perspective: 1000px;
	margin: 5%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  /* align-items: center;
  vertical-align: middle; */
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* vertical-align: middle; */
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  /* background-color: #2980b9; */
  background-color: rgba(0,0,0,.7);
  color: white;
  transform: rotateY(180deg);
  /* vertical-align: middle; */
  line-height: 146px;
  /* text-align: center; */
  /* display: table-cell; */
}
/* .flip-card-back>p {
  position: absolute;
  top: 40%;
  left: 5%; */
  /* text-align: center; */
  /* display: inline-block;
} */
.text-nav-card-ml {
  margin-left: 10px;
}
.text-nav-card {
  width: 150px;
  height: 150px;
  line-height: 132px;
  background-color: rgba(0,0,0,.5);
  color: #999;
  margin-top: 14px;
  /* margin-left: 4px !important; */
  transition: 0.9s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
}
.text-nav-card:hover {
  background-color: rgba(0,0,0,.7);
  color: white !important;  
  transform: rotateY(180deg);
}

.text-nav-card:hover>span {
  transform: unset;
}

.vert_ctr {
  vertical-align: middle;
  /* padding: 70px 0; */
  /* color: red; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
}

.img {
  object-fit: scale-down;
}

@media only screen and (max-width: 990px) {
  h1 {
    font-size: 100%;
  }
  
  .flip-card {
    margin: 0%;
    /* content: attr(title); */
    /* display: inline-block ; */
    /* display: hidden; */
    /* content: "xxx";
    color: red; */
    /* z-index: 100; */
  }
  .flip-card-back {
    /* display: inline-block; */
    display: show;
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-inner {
    /* display: inline-block; */
    display: hidden;
  }

  .text-nav-card {
    /* transform: none; */
    display: inline-block;
    /* align-items: center; */
  }

  .text-nav-card-ml {
    margin-left: 0;
  }

  /* .card {
    min-width: 266px;
  } */
}

/* duplicate? */
/* @media only screen and (max-width: 1350px) {
  .card {
    min-width: 266px;
  }
} */

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  /*text-shadow: 2px 2px 4px #000000; */
  /* color: black; */
  /* text-shadow: 0px 0px 8px red; */
  background-color: white;
}