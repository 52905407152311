.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo, .flip-card-front>img, nav>img {
    animation: App-logo-spin infinite 30s linear;
  }
} */

@keyframes hideshow {
  0% { opacity: 0.3; }
  25% { opacity: 0.6; }
  50% { opacity: 1; }
  75% { opacity: 0.6; }
  100% { opacity: 0.3; }
  
} 

/* .App-header>img {
  border-radius: 50%;
} */

.offset-cropped {
  /* width: 100%; */
  max-width: 100%;
  width: 100vw;
  height: 74vh;
  /* max-height: 74vh; */
  /* max-height: auto; */
  /* height: 50%; */
  background-image: url('./images/banner_big.jpg');
  background-position: left center;
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-repeat: repeat-y; */
  overflow: hidden;
  /* overflow: visible; */
  opacity: 0.5;
  background-size: cover;
  background-position: 63% 35%;

}

nav>img {
  animation: hideshow 10s linear infinite;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  /* min-width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  color: #111; 
}

/* .App-header .card-group {
  font-size: initial;
  min-height: initial; */
  /* display: initial;
  flex-direction: initial; */
  /* align-items: initial;
  
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  display: flex;
  align-items: center;
  /* background: linear-gradient(to left top, blue, red) center center fixed; */
  background: linear-gradient(to left top, white, black) center center fixed;
  background-size: cover;
  color: #ffffff;
  height: 50px;
  text-shadow: 0.25px 0.25px 0.25px #000000;
  width: 100%;
}

.footer {
  background-position: bottom;
}

.jumbotron p {
  text-align: center;
  margin: 0 0 20px;
  font-weight: bold;
  font-size: 1.5rem;
  /* background-color: #000000; */
}

@media only screen and (max-width: 700px) {
  .jumbotron {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 600px) {
  .jumbotron {
    margin-top: 110px;
  }
  .jumbotron p {
    margin: 0;
    font-size: 15px;
  }
  nav>img {
    width: 8%;
    height: 8%;
    /* display: none; */
    /* width: 50%;
    height: 50%; */
  }
  /*
  .center-cropped {
    max-width: 100%;
    max-height: 80vh;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
  }*/


}



/* .show {
  display: contents;
} */

.hide {
  display: none;
}

/* @media (max-width: 767px) */
@media (max-width: 991px)
{
  #btm-navbar
  {
      background: your-dropdown-menu-bg-color;
      bottom: your-footer-height;
      position: absolute;
      width: 100%;
  }
}