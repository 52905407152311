@import url(https://fonts.googleapis.com/css2?family=Raleway&family=Red+Rose&display=swap);
@import url(https://fonts.googleapis.com/css2?family=MuseoModerno&family=Raleway&display=swap);
* {
  font-family: 'Raleway', sans-serif;
}

h1, h2, h3 {
  /* font-family: 'Red Rose', cursive; */
  font-family: 'MuseoModerno', cursive;
}

.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}

body {
  margin: 0;

  /* font-family: 'MuseoModerno', cursive !important; */

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scss style to re-add upon scss conversion */
/* $enable-shadows:true; */

.btn-primary {
    color: palegreen;
    background-color: darkcyan;
    border-color: darkcyan;
}
 
.mycard {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	width: 40%;
	margin: 5%;
}

/* On mouse-over, add a deeper shadow */
.mycard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.mycontainer {
	padding: 2px 16px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* .flip-card-outer {
  display: inline-block;
} */

.flip-card {
  /* display: inline-block; */
  background-color: transparent;
  width: 150px; /* 300px; */
  height: 150px; /* 300px; */
  object-fit: cover;
	perspective: 1000px;
	margin: 5%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  /* align-items: center;
  vertical-align: middle; */
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* vertical-align: middle; */
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  /* background-color: #2980b9; */
  background-color: rgba(0,0,0,.7);
  color: white;
  transform: rotateY(180deg);
  /* vertical-align: middle; */
  line-height: 146px;
  /* text-align: center; */
  /* display: table-cell; */
}
/* .flip-card-back>p {
  position: absolute;
  top: 40%;
  left: 5%; */
  /* text-align: center; */
  /* display: inline-block;
} */
.text-nav-card-ml {
  margin-left: 10px;
}
.text-nav-card {
  width: 150px;
  height: 150px;
  line-height: 132px;
  background-color: rgba(0,0,0,.5);
  color: #999;
  margin-top: 14px;
  /* margin-left: 4px !important; */
  transition: 0.9s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
}
.text-nav-card:hover {
  background-color: rgba(0,0,0,.7);
  color: white !important;  
  transform: rotateY(180deg);
}

.text-nav-card:hover>span {
  transform: unset;
}

.vert_ctr {
  vertical-align: middle;
  /* padding: 70px 0; */
  /* color: red; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
}

.img {
  object-fit: scale-down;
}

@media only screen and (max-width: 990px) {
  h1 {
    font-size: 100%;
  }
  
  .flip-card {
    margin: 0%;
    /* content: attr(title); */
    /* display: inline-block ; */
    /* display: hidden; */
    /* content: "xxx";
    color: red; */
    /* z-index: 100; */
  }
  .flip-card-back {
    /* display: inline-block; */
    display: show;
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-inner {
    /* display: inline-block; */
    display: hidden;
  }

  .text-nav-card {
    /* transform: none; */
    display: inline-block;
    /* align-items: center; */
  }

  .text-nav-card-ml {
    margin-left: 0;
  }

  /* .card {
    min-width: 266px;
  } */
}

/* duplicate? */
/* @media only screen and (max-width: 1350px) {
  .card {
    min-width: 266px;
  }
} */

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  /*text-shadow: 2px 2px 4px #000000; */
  /* color: black; */
  /* text-shadow: 0px 0px 8px red; */
  background-color: white;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo, .flip-card-front>img, nav>img {
    animation: App-logo-spin infinite 30s linear;
  }
} */

@-webkit-keyframes hideshow {
  0% { opacity: 0.3; }
  25% { opacity: 0.6; }
  50% { opacity: 1; }
  75% { opacity: 0.6; }
  100% { opacity: 0.3; }
  
}

@keyframes hideshow {
  0% { opacity: 0.3; }
  25% { opacity: 0.6; }
  50% { opacity: 1; }
  75% { opacity: 0.6; }
  100% { opacity: 0.3; }
  
} 

/* .App-header>img {
  border-radius: 50%;
} */

.offset-cropped {
  /* width: 100%; */
  max-width: 100%;
  width: 100vw;
  height: 74vh;
  /* max-height: 74vh; */
  /* max-height: auto; */
  /* height: 50%; */
  background-image: url(/static/media/banner_big.06afe88a.jpg);
  background-position: left center;
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-repeat: repeat-y; */
  overflow: hidden;
  /* overflow: visible; */
  opacity: 0.5;
  background-size: cover;
  background-position: 63% 35%;

}

nav>img {
  -webkit-animation: hideshow 10s linear infinite;
          animation: hideshow 10s linear infinite;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  /* min-width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  color: #111; 
}

/* .App-header .card-group {
  font-size: initial;
  min-height: initial; */
  /* display: initial;
  flex-direction: initial; */
  /* align-items: initial;
  
} */

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  display: flex;
  align-items: center;
  /* background: linear-gradient(to left top, blue, red) center center fixed; */
  background: linear-gradient(to left top, white, black) center center fixed;
  background-size: cover;
  color: #ffffff;
  height: 50px;
  text-shadow: 0.25px 0.25px 0.25px #000000;
  width: 100%;
}

.footer {
  background-position: bottom;
}

.jumbotron p {
  text-align: center;
  margin: 0 0 20px;
  font-weight: bold;
  font-size: 1.5rem;
  /* background-color: #000000; */
}

@media only screen and (max-width: 700px) {
  .jumbotron {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 600px) {
  .jumbotron {
    margin-top: 110px;
  }
  .jumbotron p {
    margin: 0;
    font-size: 15px;
  }
  nav>img {
    width: 8%;
    height: 8%;
    /* display: none; */
    /* width: 50%;
    height: 50%; */
  }
  /*
  .center-cropped {
    max-width: 100%;
    max-height: 80vh;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
  }*/


}



/* .show {
  display: contents;
} */

.hide {
  display: none;
}

/* @media (max-width: 767px) */
@media (max-width: 991px)
{
  #btm-navbar
  {
      background: your-dropdown-menu-bg-color;
      bottom: your-footer-height;
      position: absolute;
      width: 100%;
  }
}
.Tiles_Tiles__3RPGy {

}

/* @media only screen and (max-width: 1350px) {
  .card {
    min-width: 266px;
  }
} */

/* .Tiles img {
  min-width: 266px;
} */

.Tiles_hr_inline__2qHWz {
    display: inline;
    text-align: left;
  }

hr {
      
    border: 1px solid lightgrey;
  }


.PicTile {
    /*flex: 0 1 300px; /*  No stretching: */
    width: 60%;
    margin: 16px auto;
    /* margin: auto; */
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 16px;
    text-align: center;
}

.card-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*border: 3px solid green !important; /* WORKS! */
}

.card {
    flex: 0 1 300px !important; /*  No stretching: */
    /*border: 3px solid red !important; /* WORKS! */
}

.card-title {
    position: absolute;
    bottom: 0px;
    margin-top: 25%;
    right: 0%;
    left: 0%;
}

.card-body {
    /* .card-title>h3 { */
    margin-top: 32px;
}
/* adding PicTile class to prevent styling from going globally */
