.PicTile {
    /*flex: 0 1 300px; /*  No stretching: */
    width: 60%;
    margin: 16px auto;
    /* margin: auto; */
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 16px;
    text-align: center;
}

.card-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*border: 3px solid green !important; /* WORKS! */
}

.card {
    flex: 0 1 300px !important; /*  No stretching: */
    /*border: 3px solid red !important; /* WORKS! */
}

.card-title {
    position: absolute;
    bottom: 0px;
    margin-top: 25%;
    right: 0%;
    left: 0%;
}

.card-body {
    /* .card-title>h3 { */
    margin-top: 32px;
}
/* adding PicTile class to prevent styling from going globally */